<template>
  <div class="church-details template-2">
    <!-- / Page Header -->
    <form>
      <div class="page-body container mx-lg">
        <div class="details">
          <h2 class="">{{ translations.tcAddCalendar }}</h2>
          <div class="b-container">
            <div class="row">
              <div class="namediv">
                <h5>{{ translations.tcName }}</h5>
              </div>
            </div>
            <div class="row">
              <div>
                <input type="text" maxlength="50" v-model="categoryName" />
              </div>
            </div>
          </div>
        </div>
        <div class="divButton">
          <b-button variant="primary" class="flex-0 w-100-sd mb-sm-0" size="sm" @click="createCalendar()">
            {{ translations.tcSave }}
          </b-button>
          &nbsp;
          <b-button variant="light" class="flex-0 w-100-sd mb-sm-0" size="sm" @click="cancel()">
            {{ translations.tcCancel }}
          </b-button>
        </div>
      </div>
    </form>
  </div>
  <!-- / Page Body -->
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'prayer-edit-calendar',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      categoryName: '',
      errorMessages: [],
    }
  },
  components: {
    appEvent: Event,
  },
  methods: {
    ...mapActions({
      addCalendar: 'prayerCenter/addCalendar',
      getCategoryTypes: 'prayerCenter/getCategoryTypes',
      setCalendarItem: 'prayerCenter/setCalendarItem',
      setLoadingStatus: 'menu/setLoadingStatus',
      setPrayerItemAndLevel: 'prayerCenter/setPrayerItemAndLevel',
      updateCalendar: 'prayerCenter/updateCalendar',
    }),
    cancel() {
      this.$router.push({ name: 'prayer-add-edit-calendar' })
    },
    validate() {
      // clear previous messages
      this.errorMessages = []
      if (this.categoryName === '') {
        this.errorMessages.push(this.translations.tcErrorValidCategoryName)
        return false
      }
      let category = this.categories.filter(
        (category) => category.name.toLowerCase() == this.categoryName.toLowerCase()
      )

      if (category.length > 0) {
        this.errorMessages.push(this.translations.tcErrorNameAlreadyExists)
        return false
      }
      return true
    },
    async new() {
      try {
        this.setLoadingStatus(true)
        if (!this.validate()) {
          this.$swal({
            icon: 'error',
            text: this.errorMessages.join(', '),
            confirmButtonText: this.translations.tcOk || 'Ok',
          })
          return
        }
        let data = {
          AddUser: this.userIndividualKey,
          CategoryName: this.categoryName,
          LangKey: this.userLanguageKey,
          TypeKey: this.dailyTypeKey,
          Year: new Date().getFullYear(),
        }
        let params = {
          data: data,
          orgKey: this.orgKey,
        }
        let response = await this.addCalendar(params)
        if (response) {
          await this.setCalendarItem({
            active: true,
            category: this.categoryName,
            key: response,
            language: this.userPreferredLanguage,
            name: this.categoryName,
            level: this.prayerLevel,
          })
          await this.setPrayerItemAndLevel({ item: {}, level: this.prayerLevel })
          this.$router.push({ name: 'prayer-manage-calendar' })
        } else {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorWithServer,
            confirmButtonText: this.translations.tcOk || 'Ok',
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async update() {
      try {
        this.setLoadingStatus(true)
        let params = this.prayerItem
        params.name = this.categoryName
        params.user = this.userIndividualKey
        if (!this.validate()) {
          this.$swal({
            icon: 'error',
            text: this.errorMessages.join(', '),
            confirmButtonText: this.translations.tcOk || 'Ok',
          })
          return
        }

        let response = await this.updateCalendar(params)
        if (response) {
          this.$router.push({ name: 'prayer-add-edit-calendar' })
        } else {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorWithServer,
            confirmButtonText: this.translations.tcOk || 'Ok',
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async createCalendar() {
      if (!this.prayerItem || !this.prayerItem.name) {
        this.new()
      } else {
        this.update()
      }
    },
    async pageLoad() {
      try {
        await Promise.all([this.setLoadingStatus(true), await this.getCategoryTypes(this.orgKey)]).then(() => {
          if (!!this.prayerItem) {
            this.categoryName = this.prayerItem.name
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
  },
  async created() {
    if (!this.prayerLevel) {
      return this.$router.push({ name: 'prayer-add-edit-calendar' })
    }
    await Promise.all([await this.getViewTranslations(), await this.pageLoad()])
  },
  computed: {
    ...mapGetters({
      campKey: 'user/userCampKey',
      categories: 'prayerCenter/categories',
      internationalKey: 'user/internationalKey',
      prayerItem: 'prayerCenter/prayerItem',
      prayerLevel: 'prayerCenter/prayerLevel',
      prefCulture: 'user/userPreferredCulture',
      stateKey: 'user/userStateKey',
      types: 'prayerCenter/types',
      userIndividualKey: 'user/userId',
      userLanguageKey: 'user/userLanguageKey',
      userPreferredLanguage: 'user/userPreferredLanguage',
    }),
    orgKey() {
      return (
        { camp: this.campKey, country: this.stateKey, state: this.stateKey, headquarters: this.internationalKey }[
          this.prayerLevel
        ] || ''
      )
    },
    dailyTypeKey() {
      return this.types.find((type) => type.name.toLowerCase() == 'daily').key
    },
  },
}
</script>
<style lang="scss" scoped>

div.container {
  padding: 20px 20px;
  padding-bottom: 50px;
}
input[type='text'] {
  width: 700px;
}
</style>
<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h2 {
  text-transform: uppercase;
}

b-button {
  text-transform: uppercase;
}


div.b-container {
  padding: 20px 20px;
}

.church-details {
  form {
    .quick-jump {
      min-width: 201px;
    }
  }
}

div.namediv {
  h5 {
    font-weight: 600;
    margin-top: 10px;
    text-transform: uppercase;
  }
  width: 80px;
}

div.numberdays {
  h6 {
    font-size: 14px;
    margin-top: 15px;
    font-weight: 600;
  }
}

div.row {
  margin-top: 10px;
}

textarea {
  width: 100%;
  height: 250px;
}

div.divButton {
  margin-left: 10px;
}

div.divDetail {
  h5 {
    font-weight: 600;
    margin-top: 5px;
  }
  margin-top: 50px;
}

.details {
  margin-bottom: 46px;
  padding: 40px 40px;
  background-color: #fff;
  box-shadow: 0 9px 24px 6px rgba(197, 197, 197, 0.53);

  h2 {
    color: #000;
  }

  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
      > * {
        margin-bottom: 2rem;
        margin-right: 0 !important;
        width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

#example-datepicker__outer_ .btn {
  color: #212529;
}

input {
  line-height: 40px;
}

input[type='number'] {
  width: 50px;
}
.primary {
  margin-bottom: 35px;
  > .col {
    @include breakpoint(sm) {
      flex: 0 0 auto;
      width: 100%;
    }
  }
}
.upload-image {
  margin-top: 30px !important;
}
</style>
